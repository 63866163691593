/* Font */
@font-face {
  font-family: PoppinsLight;
  src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: PoppinsRegular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: PoppinsMedium;
  src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: PoppinsItalic;
  src: url(../fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: PoppinsBoldItalic;
  src: url(../fonts/Poppins-BoldItalic.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: PoppinsExtraBold;
  src: url(../fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: PoppinsBlack;
  src: url(../fonts/Poppins-Black.ttf);
}

/* BOOTSTRAP */
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

$font: "PoppinsRegular";
$fontL: "PoppinsLight";
$fontI: "PoppinsItalic";
$fontM: "PoppinsMedium";
$fontSm: "PoppinsSemiBold";
$fontB: "PoppinsBold";
$fontEB: "PoppinsExtraBold";
$fontBi: "PoppinsBoldItalic";
$fontBl: "PoppinsBlack";

$primary: #ff5c40;
$lightPrimary: #ffcec5;
$darkPrimary: #a5250f;
$secondary: #5252d1;
$darkSecondary: #3e3d96;
$greenLight: #7fffd4;
$gray: #d3d3d3;
$darkGray: #666666;
$lightPurple: #d1b8ff;
$purple: #57229d;
$darkPurple: #2b1b4a;
$light: #ffd1ba;
$white: #fefefe;

/* SIDEBAR */
@import "./sidebar.scss";

// General
body {
  font-family: $font;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $darkSecondary;
    transition: 0.7s;
  }

  &.menu {
    &:hover {
      color: $darkSecondary;
      transition: 0.7s;
    }
  }
}

.color-primary {
  color: $primary;
}
.color-secondary {
  color: $secondary;
}
.color-gray {
  color: $gray;
}
.color-dark-gray {
  color: $darkGray;
}
.color-light {
  color: $light;
}
.color-white {
  color: $white;
}
.bg-color-white {
  background-color: $white;
}
.bg-color-primary {
  background: $primary;
}
.bg-color-light-gray {
  background-color: #dedede;
}
.bg-login {
  background-image: linear-gradient(to right, transparent 40%, #ff5c40 100%),
    url("../img/bg_laranja.png");
  background-size: cover;
  background-position: center;
}
.c-pointer {
  cursor: pointer;
}

.font-light {
  font-family: $fontL;
}
.font-italic {
  font-family: $fontI;
}
.font-bold-italic {
  font-family: $fontBi;
}
.font-medium {
  font-family: $fontM;
}
.font-semi-bold {
  font-family: $fontSm;
}
.font-bold {
  font-family: $fontB;
}
.font-extra-bold {
  font-family: $fontEB;
}
.font-black {
  font-family: $fontBl;
}

.section-height-100 {
  min-height: 100vh;
  display: flex;

  &.center-items {
    justify-content: center;
    align-items: center;
  }
}

.padding-container {
  padding: 8rem 0rem;
}
.padding-card {
  padding: 4rem 3.5rem;
}

.card-form {
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.2);
  border: 0px;

  &.card-invitation {
    margin: 2rem;
    width: 50%;

    & .body-invitation {
      padding: 2rem 3.5rem;
      text-align: center;

      & .material-icons {
        font-size: 2.5rem;
      }
    }
  }

  &.reset-password {
    margin: 2rem;
    max-width: 60%;
  }
}

.card-data {
  border-width: 0px;

  &.appointments {
    border-radius: 0.375rem !important;
  }
}

.input-search {
  background-color: transparent;
  border: 1px #dfdfdf solid;
  border-radius: 0px;
  padding: 0.75rem 1rem;
  font-size: 16px;
  font-family: $font;
  color: #000;

  &::placeholder {
    font-family: $fontL;
    font-size: 14px;
    color: #999999;
  }
}

.patients-modal {
  z-index: 1056;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  box-shadow: 0px 0px 8px 7px rgba(0, 0, 0, 0.2);

  & .modal-footer {
    background-color: #dedede;
    border-top: 0px;
  }
}

form {
  & input,
  & select {
    &.form-control {
      background-color: transparent;
      border: 1px #dfdfdf solid;
      border-radius: 0px;
      padding: 0.75rem 1rem;
      font-size: 16px;
      font-family: $font;
      color: #000;

      &::placeholder {
        font-family: $fontL;
        font-size: 14px;
        color: #999999;
      }

      &:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: $darkPrimary;
        outline: 0;
        box-shadow: 0 0 0.25rem rgba(0, 26, 31, 0.5);
      }
    }
  }

  .form-check-input[type="checkbox"] {
    border-radius: 0px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
    border: 1px #a5a5a5 solid;
  }

  & .small-info:hover {
    cursor: pointer;
    transition: 0.6s;
    color: $darkSecondary;
  }

  & .btn-primary {
    font-size: 12px;
    line-height: 50px;
    font-weight: 600;
    float: right;
    margin-top: 10px;
    padding: 0.2rem 3.5rem;
    background-color: $primary;
    border: 1px solid $primary;
    color: #fefefe;
    border-radius: 0px;
    background-image: linear-gradient(45deg, $darkPrimary 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 750ms ease-in-out;
    transition: 0.7s;

    &.signup {
      background-color: #000;
      color: white;
      border: 1px solid #000;
    }

    &:hover,
    &:active,
    &:focus-visible {
      border: 1px solid $darkPrimary !important;
      background-position: 0;
      background-color: $darkPrimary !important;
      transition: 0.7s;
      color: #fefefe;
    }

    &:disabled {
      background-color: $lightPrimary;
      border-color: $lightPrimary;
      color: $darkPrimary;
      transition: 0.7s;
    }

    &:focus-visible {
      box-shadow: 0 0 0.25rem rgba(0, 26, 31, 0.5);
    }
  }
}

.btn-group .btn.btn-pagination {
  font-size: 12px;
  color: #fefefe;
  background-color: $primary;
  border-color: $primary;
  transition: 0.7s;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: $primary;
    transition: 0.7s;
  }
  &:disabled {
    background-color: $darkPrimary;
    border-color: $darkPrimary;
  }
}

.custom-btn {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 0px;
  color: #fefefe;
  background-color: $primary;
  border: 1px solid $primary;
  background-image: linear-gradient(30deg, $darkPrimary 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 750ms ease-in-out;
  transition: 0.7s;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;

  &.btn-app {
    background-color: $primary;
    border: 1px solid $primary;
    color: #fefefe;
    padding: 0.5rem 1rem;

    &.btt-add {
      height: 50px;
      width: 100%;
      display: block;
    }

    &.btn-add-solo {
      height: 50px;
      width: 15%;
      display: block;

      &.solo-attach {
        background-color: $purple;
        border: 1px solid $purple;
        background-image: linear-gradient(
          30deg,
          $darkPurple 50%,
          transparent 50%
        );
      }
    }

    &.btt-export {
      height: 50px;
      width: 100%;
      display: block;
      background-color: $darkPrimary;
      border: 1px solid $darkPrimary;
      background-image: linear-gradient(30deg, #5702a7 50%, transparent 50%);

      & i {
        margin-top: 5px;
        color: #fff;
        transition: 0.3s;
      }
    }
  }

  & span {
    font-size: 13px;
    line-height: 40px;
    font-weight: 500;
    padding: 0px 25px;
    cursor: pointer;
    transition: 0.1s;
  }

  &:hover,
  &:active,
  &:focus-visible {
    border: 1px solid $darkPrimary !important;
    background-position: 0;
    background-color: $darkPrimary !important;
    transition: 0.7s;
    color: #fefefe;

    &.btt-export {
      & i {
        color: #dedede;
        transition: 0.3s;
      }
    }
  }
}

.dropdown-menu.show {
  & .dropdown-item {
    &:hover {
      background-color: $primary;
      color: #fefefe !important;
      transition: 0.7s;
    }
  }
}

.dropdown-toggle::after,
.dropdown-toggle::before {
  display: none !important;
}

hr.hr-page {
  margin-left: 80%;
  margin-right: 1rem;
  margin-top: 0.6rem;
  border-color: $darkGray;
}

.label-day {
  margin: 0.3rem 0.5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 15rem;
  background-color: $lightPrimary;
  text-align: center;
  color: $darkPrimary;
  cursor: pointer;
  width: 180px;
  opacity: 0.65;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  &:hover,
  &.selected {
    background-color: $darkPrimary;
    color: $lightPrimary;
    opacity: 1;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }
}

.label-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  color: $darkGray;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 0.3s ease-out;

  & .icon {
    font-size: 45px;
  }

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: $primary;
  }
}

hr {
  &.hr-25 {
    margin-right: 75%;
    border-color: #ababab;
  }
}

// Loading
.loading {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);

  &:before,
  &:after,
  div {
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 4px solid $lightPrimary;
  }

  &:before {
    content: "";
    animation: scale 1s ease-in infinite;
  }
  &:after {
    content: "";
    animation: scale 2s ease-in infinite;
  }

  div {
    animation: scale 3s ease-in infinite;
  }

  &.open {
    display: block;
    position: fixed;
    top: 0;
    z-index: 99999;
  }
}

@keyframes scale {
  from {
    transform: translate(-50%, -50%) scale(0, 0);
  }
  to {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@media (min-width: 992px) {
  .card-form.card-login {
    max-width: 64%;
  }
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 60%;
  }
}

@media (max-width: 992px) {
  .card-form {
    &.card-login {
      padding: 0.5rem;

      .padding-card {
        padding: 2rem 1.5rem;
      }
    }

    &.card-invitation {
      width: 90%;

      & .body-invitation {
        padding: 1rem 2.5rem;
      }
    }
  }

  .label-day {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .btn-add-solo {
    width: 25% !important;
  }
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 90%;
  }
}
