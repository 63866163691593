.navbar {
  background: $white;

  i {
    cursor: pointer;
  }
  .navbar-page {
    font-size: 14px;
    margin-left: 10px;
  }
  .navbar-nav {
    .nav-link {
      color: $gray;
      font-size: 14px;

      &:hover {
        color: $secondary;
      }
    }
  }
  .flags {
    img {
      width: 20px;
      margin: 0 5px;
    }
  }

  .dropdown {
    .dropdown-toggle {
      background: transparent !important;
      border: transparent;
      display: flex;
      align-items: center;
      padding: 0;

      &:focus {
        box-shadow: none;
      }

      &::after {
        display: none;
      }

      i {
        color: $gray;
      }
    }
  }
}

#sidebar-wrapper {
  min-height: 100vh;
  background-color: $primary;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 250px;
  -webkit-transition: width 0.25s ease-out;
  -moz-transition: width 0.25s ease-out;
  -o-transition: width 0.25s ease-out;
  transition: width 0.25s ease-out;

  .sidebar-heading {
    padding: 2.2rem 0 1.4rem;
    font-size: 1.2rem;
    h3 {
      font-size: 1.2rem;
      color: $white;
    }
    a {
      transition: 0.3s;

      img {
        width: 100%;
        padding: 1rem 0.5rem;
      }

      &:hover {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
  }

  .list-group {
    width: 100%;
    overflow: hidden;

    .list-group-item {
      background-color: $primary;
      color: $white;
      font-size: 15px;
      border: 0;
      transition: 0.6s;
      margin-left: 0.3rem;
      transition: background-color 0.6s ease, color 0.6s ease;

      &:hover {
        color: $darkSecondary;
        font-weight: bold;
        background-color: #fff;
        border-top-left-radius: 40px 40px;
        border-bottom-left-radius: 40px 40px;

        & i,
        span {
          color: $darkSecondary;
          transition: color 0.6s ease;
        }
      }

      &.item-active {
        color: $darkSecondary;
        font-weight: bold;
        background: #fff;
        transition: 0.6s;
        border-top-left-radius: 40px 40px;
        border-bottom-left-radius: 40px 40px;

        & i,
        span {
          color: $darkSecondary;
          transition: 0.6s;
        }
      }

      i,
      span {
        color: $white;
        margin-right: 10px;
      }
      .name-list {
        margin-top: 3px;
      }
    }

    .list-group-subitem {
      padding-left: 65px;

      strong {
        padding: 5px;
        border-radius: 0.25rem;
        font-size: 10px;
      }
    }
  }

  .accordion {
    background-color: $primary;
    .card {
      .card-header {
        background-color: $primary;

        button {
          color: $gray;
        }
      }
      .collapse {
        background-color: $primary;
        .card-body {
          a {
            font-size: 13px;
            color: $white;
          }
        }
      }
      .collapsing {
        background-color: $primary;
        .card-body {
          a {
            font-size: 13px;
            color: $white;
          }
        }
      }
    }

    .padding-separate {
      display: flex;
      justify-content: center;
      .separate {
        width: 50%;
        border-top: 1px solid white;
      }
    }
  }

  .sidebar-logout-container {
    position: absolute;
    bottom: 0;
    width: 100%;

    & .clinic-div {
      .selected-clinic {
        &:hover {
          background-color: $white;
          color: $darkSecondary;
          transition: 0.6s;
        }
      }
    }
  }

  & hr.hr-sidebar {
    margin-right: 50%;
    margin-top: 0.6rem;
    margin-bottom: 0.1rem;
    border-color: $white;
  }
}

#page-content-wrapper {
  padding-left: 250px;
  min-width: 100vw;
  min-height: 100vh;

  -webkit-transition: padding 0.25s ease-in;
  -moz-transition: padding 0.25s ease-in;
  -o-transition: padding 0.25s ease-in;
  transition: padding 0.25s ease-in;
}

#wrapper.toggled {
  & #sidebar-wrapper {
    margin-left: 0;
    width: 0px;
    -webkit-transition: margin-left 0.25s ease-out, width 0.25s ease-out;
    -moz-transition: margin-left 0.25s ease-out, width 0.25s ease-out;
    -o-transition: margin-left 0.25s ease-out, width 0.25s ease-out;
    transition: margin-left 0.25s ease-out, width 0.25s ease-out;
  }

  & #page-content-wrapper {
    padding-left: 0;
    -webkit-transition: padding 0.25s ease-out;
    -moz-transition: padding 0.25s ease-out;
    -o-transition: padding 0.25s ease-out;
    transition: padding 0.25s ease-out;
  }
}

.dropdown-toggle {
  &:hover {
    span {
      color: $darkSecondary;
      transition: 0.7s;
    }
  }
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
    max-width: 250px;
    -webkit-transition: margin 0.25s ease-in;
    -moz-transition: margin 0.25s ease-in;
    -o-transition: margin 0.25s ease-in;
    transition: margin 0.25s ease-in;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
    -webkit-transition: margin-left 0.25s ease-in, width 0.25s ease-in;
    -moz-transition: margin-left 0.25s ease-in, width 0.25s ease-in;
    -o-transition: margin-left 0.25s ease-in, width 0.25s ease-in;
    transition: margin-left 0.25s ease-in, width 0.25s ease-in;
  }
}
